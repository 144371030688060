import { Collapse, List, ListItem, ListItemIcon, ListItemText, SvgIconTypeMap, Theme, createStyles, makeStyles } from "@material-ui/core";
import { FunctionComponent, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import ColorPalette from "../../theme/ColorPalette";
import { IMicroFrontendSubMenuItem } from "../../microfrontends";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { SubMenuItem } from "./SubMenuItem";
import { hasRequiredGroup } from "../../exports";
import AuthenticatedContext from "../../AuthenticatedContext";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeLink: {
      backgroundColor: ColorPalette.primary.main,
      '&:hover': {
        backgroundColor: ColorPalette.primary.main + '!important',
      }
    },
    subMenuLevel1: {
      marginLeft: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      borderLeft: '1px solid',
      borderColor: theme.palette.common.white,
      fontSize: '12px',
      '& .MuiListItem-gutters': {
        padding: '10px 16px'
      },
      '& .MuiListItemIcon-root': {
        minWidth: '30px',
        '& .MuiSvgIcon-root': {
          fontSize: '18px'
        }
      },
      '& .MuiTypography-root': {
        fontSize: '12px'
      }
    },
  }),
);

interface IBaseMenuItem {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

interface IMenuItemWithoutSubMenu extends IBaseMenuItem {
  path: string;
  subMenuItems?: never;
}

interface IMenuItemWithSubMenu extends IBaseMenuItem {
  path?: never;
  subMenuItems: IMicroFrontendSubMenuItem[];
}

export type MenuItemProps = IMenuItemWithoutSubMenu | IMenuItemWithSubMenu

export const MenuItem: FunctionComponent<MenuItemProps> = ({icon: Icon, path, subMenuItems = [], title}) => {

  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const toggleSubMenu = () => setSubMenuOpen(!subMenuOpen)

  const classes = useStyles();

  const { groups } = useContext(AuthenticatedContext)

  if (path) {
    return <ListItem button component={NavLink} to={path} activeClassName={classes.activeLink}>
      <ListItemIcon>
        <Icon fontSize={'small'} />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  }

  return <><ListItem button onClick={toggleSubMenu}>
    <ListItemIcon>
      <Icon fontSize={'small'} />
    </ListItemIcon>
    <ListItemText primary={title} />
    {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
  </ListItem>
  <Collapse className={classes.subMenuLevel1} in={subMenuOpen} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      {subMenuItems.filter(item => hasRequiredGroup(item.requiredGroups, groups))
        .map((item, index) => <SubMenuItem key={`submenu-item-${index}`} path={item.path} title={item.title}/>)}
    </List>
  </Collapse>
  </>

}