type EnvironmentSpecificConfig = {
  COCKPIT_API_URL: string;
  COCKPIT_API_URL_PREFIX: string;
  COGNITO_REDIRECT_SIGN_IN: string;
  COGNITO_REDIRECT_SIGN_OUT: string;
  COGNITO_IDENTITY_POOL_ID: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_USER_POOL_WEBCLIENT_ID: string;
  COGNITO_DOMAIN: string;
  BADGE_COCKPIT_URL: string;
  LU_SETTINGS_COCKPIT_URL: string;
  LWM_SETTINGS_COCKPIT_URL: string;
  LU_PREVIEW_COCKPIT_URL: string;
  CERTIFICATES_COCKPIT_URL: string;
  EMAIL_NOTIFICATIONS_COCKPIT_URL: string;
  LEHRGANG_BOOKINGS_COCKPIT_URL: string;
  ORGANIZATIONS_COCKPIT_URL: string;
  FEEDBACK_COCKPIT_URL: string;
};

const ALLOWED_GROUPS = {
  SUPER_USER: 'Superuser',
  LU_PREVIEW: 'LUPreview',
  DIGITAL_TB: 'DigitalTB',
  DIGITAL_TB_WRITE: 'DigitalTBWrite',
  SURVEY_MAPPINGS_READ: 'SurveyMappingsRead',
  SURVEY_MAPPINGS_WRITE: 'SurveyMappingsWrite',
  SURVEY_MAPPINGS_ILS_READ: 'SurveyMappingsILSRead',
  SURVEY_MAPPINGS_ILS_WRITE: 'SurveyMappingsILSWrite',
  SURVEY_MAPPINGS_IHC_READ: 'SurveyMappingsIHCRead',
  SURVEY_MAPPINGS_IHC_WRITE: 'SurveyMappingsIHCWrite',
  BADGES: 'Badges',
  BADGE_MANAGEMENT: 'BadgeManagement',
  BADGE_ISSUE_MANAGEMENT: 'BadgeIssueManagement',
  LU_SETTINGS: 'LUSettings',
  SURVEY_LINKS: 'SurveyLinks',
  SURVEY_LINKS_WRITE: 'SurveyLinksWrite',
  EMAIL_NOTIFICATIONS: 'EmailNotifications',
  LWM_SETTINGS: 'LwmSettings',
  LEHRGANG_BOOKINGS: 'LehrgangBookings',
  ORGANIZATIONS_COCKPIT: 'OrganizationsCockpit',
  ORGANIZATIONS_COCKPIT_PREVIEW: 'OrganizationsCockpitPreview',
  CERTIFICATES_WRITE: 'CertificatesWrite',
  FEEDBACK_REPORT: 'FeedbackReport',
};

const dev: EnvironmentSpecificConfig = {
  COCKPIT_API_URL: 'http://localhost:8080',
  COCKPIT_API_URL_PREFIX: '/dev',
  COGNITO_REDIRECT_SIGN_IN: 'http://localhost:4000',
  COGNITO_REDIRECT_SIGN_OUT: 'http://localhost:4000',
  COGNITO_IDENTITY_POOL_ID: 'eu-central-1:65603573-eac8-48f7-b4fa-a28d86cfbf12',
  COGNITO_USER_POOL_ID: 'eu-central-1_NATuP1y8b',
  COGNITO_USER_POOL_WEBCLIENT_ID: 'pn93j5ltsvmsv2551b3g6luaa',
  COGNITO_DOMAIN: 'lu-cockpit.auth.eu-central-1.amazoncognito.com',
  LEHRGANG_BOOKINGS_COCKPIT_URL: 'https://microfrontend-lehrgang-buchungen.lu.akadev.de/static/js/main.js',
  ORGANIZATIONS_COCKPIT_URL: 'https://cockpit-microfrontend.organizations.lu.ban.akadev.de/static/js/main.js',
  LWM_SETTINGS_COCKPIT_URL: 'https://microfrontend-lwm-settings.lwm.haufe-akademie.de/static/js/main.js',
  CERTIFICATES_COCKPIT_URL: 'https://cockpit-microfrontend.certifications.lu.ban.akadev.de/static/js/main.js',
  FEEDBACK_COCKPIT_URL: 'https://cockpit-microfrontend.feedback.lu.ban.akadev.de/static/js/main.js',
  LU_PREVIEW_COCKPIT_URL: 'https://lu-preview.lernumgebung-test.lu.akadev.de/static/js/main.js',
  BADGE_COCKPIT_URL: 'https://cockpit.haufe-badges-test.com/static/js/main.js',
  LU_SETTINGS_COCKPIT_URL: 'https://lu-settings.test.luk.haufe-akademie.de/static/js/main.js',
  EMAIL_NOTIFICATIONS_COCKPIT_URL: 'https://microfrontend-email-notifications.lu.akadev.de/static/js/main.js',
};

const test: EnvironmentSpecificConfig = {
  COCKPIT_API_URL: 'https://vpce-0aaa75251ef9131a2-4968zm6m.execute-api.eu-central-1.vpce.amazonaws.com',
  COCKPIT_API_URL_PREFIX: '/test',
  COGNITO_REDIRECT_SIGN_IN: 'https://cockpit.lu.akadev.de',
  COGNITO_REDIRECT_SIGN_OUT: 'https://cockpit.lu.akadev.de',
  COGNITO_IDENTITY_POOL_ID: 'eu-central-1:65603573-eac8-48f7-b4fa-a28d86cfbf12',
  COGNITO_USER_POOL_ID: 'eu-central-1_NATuP1y8b',
  COGNITO_USER_POOL_WEBCLIENT_ID: 'pn93j5ltsvmsv2551b3g6luaa',
  COGNITO_DOMAIN: 'lu-cockpit.auth.eu-central-1.amazoncognito.com',
  LEHRGANG_BOOKINGS_COCKPIT_URL: 'https://microfrontend-lehrgang-buchungen.lu.akadev.de/static/js/main.js',
  ORGANIZATIONS_COCKPIT_URL: 'https://cockpit-microfrontend.organizations.lu.ban.akadev.de/static/js/main.js',
  LWM_SETTINGS_COCKPIT_URL: 'https://microfrontend-lwm-settings.lwm.haufe-akademie.de/static/js/main.js',
  BADGE_COCKPIT_URL: 'https://cockpit.haufe-badges-test.com/static/js/main.js',
  LU_SETTINGS_COCKPIT_URL: 'https://lu-settings.test.luk.haufe-akademie.de/static/js/main.js',
  CERTIFICATES_COCKPIT_URL: 'https://cockpit-microfrontend.certifications.lu.ban.akadev.de/static/js/main.js',
  FEEDBACK_COCKPIT_URL: 'https://cockpit-microfrontend.feedback.lu.ban.akadev.de/static/js/main.js',
  LU_PREVIEW_COCKPIT_URL: 'https://lu-preview.lernumgebung-test.lu.akadev.de/static/js/main.js',
  EMAIL_NOTIFICATIONS_COCKPIT_URL: 'https://microfrontend-email-notifications.lu.akadev.de/static/js/main.js',
};

const prod: EnvironmentSpecificConfig = {
  COCKPIT_API_URL: 'https://vpce-0cc03badf80bdd296-nhys9fjq.execute-api.eu-central-1.vpce.amazonaws.com',
  COCKPIT_API_URL_PREFIX: '/prod',
  COGNITO_REDIRECT_SIGN_IN: 'https://cockpit.lernumgebung.haufe-akademie.de',
  COGNITO_REDIRECT_SIGN_OUT: 'https://cockpit.lernumgebung.haufe-akademie.de',
  COGNITO_IDENTITY_POOL_ID: 'eu-central-1:efd4caf4-d0eb-4fb3-b705-097932ad7db7',
  COGNITO_USER_POOL_ID: 'eu-central-1_SgFrJhxX5',
  COGNITO_USER_POOL_WEBCLIENT_ID: '696sc3s6hedig556ipoq48rkm0',
  COGNITO_DOMAIN: 'lu-cockpit-prod.auth.eu-central-1.amazoncognito.com',
  LEHRGANG_BOOKINGS_COCKPIT_URL:
    'https://microfrontend-lehrgang-buchungen.lernumgebung.haufe-akademie.de/static/js/main.js',
  ORGANIZATIONS_COCKPIT_URL: 'https://cockpit-microfrontend.organizations.lu.ban.haufe-akademie.de/static/js/main.js',
  BADGE_COCKPIT_URL: 'https://cockpit.haufe-badges.com/static/js/main.js',
  LU_SETTINGS_COCKPIT_URL: 'https://lu-settings.prod.luk.haufe-akademie.de/static/js/main.js',
  LWM_SETTINGS_COCKPIT_URL: 'https://microfrontend-lwm-settings.manager.lernwelt.haufe-akademie.de/static/js/main.js',
  CERTIFICATES_COCKPIT_URL: 'https://cockpit-microfrontend.certifications.lu.ban.haufe-akademie.de/static/js/main.js',
  FEEDBACK_COCKPIT_URL: 'https://cockpit-microfrontend.feedback.lu.ban.haufe-akademie.de/static/js/main.js',
  LU_PREVIEW_COCKPIT_URL: 'https://lu-preview.lernumgebung.haufe-akademie.de/static/js/main.js',
  EMAIL_NOTIFICATIONS_COCKPIT_URL:
    'https://microfrontend-email-notifications.lernumgebung.haufe-akademie.de/static/js/main.js',
};

interface IConfig extends EnvironmentSpecificConfig {
  REACT_APP_STAGE: string;
  ALLOWED_GROUPS: Record<string, string>;
  USER_GROUPS: Record<string, string[]>;
}

const config = process.env.REACT_APP_STAGE === 'test' ? test : process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

const allReadRights = [
  ALLOWED_GROUPS.LU_PREVIEW,
  ALLOWED_GROUPS.SURVEY_LINKS,
  ALLOWED_GROUPS.BADGES,
  ALLOWED_GROUPS.SURVEY_MAPPINGS_IHC_READ,
  ALLOWED_GROUPS.SURVEY_MAPPINGS_READ,
  ALLOWED_GROUPS.DIGITAL_TB,
  ALLOWED_GROUPS.LEHRGANG_BOOKINGS,
];

const mergedConfig: IConfig = {
  ...config,
  REACT_APP_STAGE: process.env.REACT_APP_STAGE ?? 'dev',
  ALLOWED_GROUPS,
  USER_GROUPS: {
    'cognito-customer-service': [
      ALLOWED_GROUPS.LU_PREVIEW,
      ALLOWED_GROUPS.SURVEY_LINKS,
      ALLOWED_GROUPS.DIGITAL_TB,
      ALLOWED_GROUPS.DIGITAL_TB_WRITE,
      ALLOWED_GROUPS.EMAIL_NOTIFICATIONS,
      ALLOWED_GROUPS.LEHRGANG_BOOKINGS,
      ALLOWED_GROUPS.LU_SETTINGS,
    ],
    'cognito-customer-service-event-support': [ALLOWED_GROUPS.SURVEY_LINKS_WRITE],
    'cognito-marketing': [],
    'cognito-pm': [
      ALLOWED_GROUPS.LU_PREVIEW,
      ALLOWED_GROUPS.DIGITAL_TB,
      ALLOWED_GROUPS.BADGES,
      ALLOWED_GROUPS.BADGE_MANAGEMENT,
      ALLOWED_GROUPS.SURVEY_MAPPINGS_READ,
      ALLOWED_GROUPS.LEHRGANG_BOOKINGS,
    ],
    'cognito-vm': [ALLOWED_GROUPS.LU_PREVIEW, ALLOWED_GROUPS.SURVEY_LINKS, ALLOWED_GROUPS.DIGITAL_TB],
    'cognito-inhouse': [
      ALLOWED_GROUPS.BADGES,
      ALLOWED_GROUPS.BADGE_MANAGEMENT,
      ALLOWED_GROUPS.BADGE_ISSUE_MANAGEMENT,
      ALLOWED_GROUPS.SURVEY_MAPPINGS_ILS_READ,
      ALLOWED_GROUPS.SURVEY_MAPPINGS_ILS_WRITE,
      ALLOWED_GROUPS.SURVEY_LINKS,
      ALLOWED_GROUPS.SURVEY_LINKS_WRITE,
      ALLOWED_GROUPS.ORGANIZATIONS_COCKPIT,
      ALLOWED_GROUPS.FEEDBACK_REPORT,
    ],
    'cognito-eacademy': [],
    // 'cognito-superuser': [ALLOWED_GROUPS.SUPER_USER],
    'cognito-inhouse-consulting': [
      ALLOWED_GROUPS.BADGES,
      ALLOWED_GROUPS.BADGE_MANAGEMENT,
      ALLOWED_GROUPS.BADGE_ISSUE_MANAGEMENT,
      ALLOWED_GROUPS.SURVEY_MAPPINGS_IHC_READ,
      ALLOWED_GROUPS.SURVEY_MAPPINGS_IHC_WRITE,
      ALLOWED_GROUPS.SURVEY_LINKS,
      ALLOWED_GROUPS.SURVEY_LINKS_WRITE,
      ALLOWED_GROUPS.ORGANIZATIONS_COCKPIT,
      ALLOWED_GROUPS.FEEDBACK_REPORT,
    ],
    'cognito-vm-events': [ALLOWED_GROUPS.SURVEY_LINKS],
    'cognito-customer-service-process': [],
    'cognito-lu': [...allReadRights, ALLOWED_GROUPS.LU_SETTINGS],
    'cognito-bum': allReadRights,
    'cognito-prml': allReadRights,
    'cognito-vmos': allReadRights,
    'cognito-ws': allReadRights,
    'cognito-lwm': [ALLOWED_GROUPS.LWM_SETTINGS],
  },
};

export default mergedConfig;
