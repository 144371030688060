import config from './config';

export interface IMicroFrontendSubMenuItem {
  title: string;
  path: string;
  routerPath?: string;
  requiredGroups: string[];
}

export interface IMicroFrontend {
  bundleUrl: string;
  navigationTitle: string;
  navigationIcon: string;
  id: string;
  requiredGroups: string[];
  subMenu: IMicroFrontendSubMenuItem[];
}

export const microfrontends: IMicroFrontend[] = [
  {
    id: 'lupreview',
    bundleUrl: config.LU_PREVIEW_COCKPIT_URL,
    navigationTitle: 'LU Vorschau',
    navigationIcon: 'Dashboard',
    requiredGroups: [config.ALLOWED_GROUPS.LU_PREVIEW],
    subMenu: [],
  },
  {
    id: 'certificates',
    bundleUrl: config.CERTIFICATES_COCKPIT_URL,
    navigationTitle: 'TBs & Zertifikate',
    navigationIcon: 'Assignment',
    requiredGroups: [
      config.ALLOWED_GROUPS.DIGITAL_TB,
      config.ALLOWED_GROUPS.DIGITAL_TB_WRITE,
      config.ALLOWED_GROUPS.CERTIFICATES_WRITE,
    ],
    subMenu: [
      {
        title: 'Teilnahmebestätigungen',
        path: '/search',
        requiredGroups: [config.ALLOWED_GROUPS.DIGITAL_TB],
      },
      {
        title: 'Zertifikate',
        path: '/certificates',
        requiredGroups: [config.ALLOWED_GROUPS.DIGITAL_TB, config.ALLOWED_GROUPS.CERTIFICATES_WRITE],
      },
    ],
  },
  {
    id: 'feedbackService',
    bundleUrl: config.FEEDBACK_COCKPIT_URL,
    navigationTitle: 'Feedback-Management',
    navigationIcon: 'Poll',
    requiredGroups: [
      config.ALLOWED_GROUPS.SURVEY_LINKS,
      config.ALLOWED_GROUPS.SURVEY_MAPPINGS_READ,
      config.ALLOWED_GROUPS.SURVEY_MAPPINGS_WRITE,
      config.ALLOWED_GROUPS.SURVEY_MAPPINGS_ILS_WRITE,
      config.ALLOWED_GROUPS.SURVEY_MAPPINGS_ILS_READ,
      config.ALLOWED_GROUPS.SURVEY_MAPPINGS_IHC_WRITE,
      config.ALLOWED_GROUPS.SURVEY_MAPPINGS_IHC_READ,
    ],
    subMenu: [
      {
        path: '/mapping-survey',
        requiredGroups: [config.ALLOWED_GROUPS.SURVEY_MAPPINGS_READ, config.ALLOWED_GROUPS.SURVEY_MAPPINGS_WRITE],
        title: 'Fragebogen-Mapping (KFF)',
      },
      {
        path: '/mapping-survey-ils',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER],
        title: 'Fragebogen-Mapping (ILS)',
      },
      {
        path: '/mapping-survey-ihc',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER],
        title: 'Fragebogen-Mapping (IHC)',
      },
      {
        path: '/mapping-questions',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER],
        title: 'Fragen-Mapping',
      },
      {
        path: '/technical-question-ids',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER],
        title: 'Technische Fragen-IDs',
      },
      {
        path: '/fb-link-query',
        routerPath: '/fb-link-query*',
        requiredGroups: [config.ALLOWED_GROUPS.SURVEY_LINKS],
        title: 'Feedback Links',
      },
      {
        path: '/feedback-report',
        routerPath: '/feedback-report*',
        requiredGroups: [config.ALLOWED_GROUPS.FEEDBACK_REPORT],
        title: 'Auswertung',
      },
    ],
  },
  {
    id: 'badges',
    bundleUrl: config.BADGE_COCKPIT_URL,
    navigationTitle: 'Badges',
    navigationIcon: 'VerifiedUser',
    requiredGroups: [config.ALLOWED_GROUPS.BADGES],
    subMenu: [],
  },
  {
    id: 'luSettings',
    bundleUrl: config.LU_SETTINGS_COCKPIT_URL,
    navigationTitle: 'LU Konfiguration',
    navigationIcon: 'Tune',
    requiredGroups: [config.ALLOWED_GROUPS.LU_SETTINGS],
    subMenu: [],
  },
  {
    id: 'emailNotifications',
    bundleUrl: config.EMAIL_NOTIFICATIONS_COCKPIT_URL,
    navigationTitle: 'E-Mail Notifications',
    navigationIcon: 'Email',
    requiredGroups: [config.ALLOWED_GROUPS.EMAIL_NOTIFICATIONS],
    subMenu: [],
  },
  {
    id: 'lwmSettings',
    bundleUrl: config.LWM_SETTINGS_COCKPIT_URL,
    navigationTitle: 'Lernweltmanager',
    navigationIcon: 'Build',
    requiredGroups: [config.ALLOWED_GROUPS.LWM_SETTINGS],
    subMenu: [
      {
        title: 'AVV-Zustimmungen',
        path: '/avv-zustimmungen',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER, config.ALLOWED_GROUPS.LWM_SETTINGS],
      },
      {
        title: 'Konfiguration',
        path: '/settings',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER, config.ALLOWED_GROUPS.LWM_SETTINGS],
      },
      {
        title: 'Mandanten',
        path: '/mandanten',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER, config.ALLOWED_GROUPS.LWM_SETTINGS],
      },
      {
        title: 'Preislisten',
        path: '/price-lists',
        requiredGroups: [config.ALLOWED_GROUPS.SUPER_USER, config.ALLOWED_GROUPS.LWM_SETTINGS],
      },
    ],
  },
  {
    id: 'lehrgangsbuchungen',
    bundleUrl: config.LEHRGANG_BOOKINGS_COCKPIT_URL,
    navigationTitle: 'Lehrgangsbuchungen',
    navigationIcon: 'School',
    requiredGroups: [config.ALLOWED_GROUPS.LEHRGANG_BOOKINGS],
    subMenu: [
      {
        title: 'Lehrgangssuche',
        path: '/search',
        requiredGroups: [config.ALLOWED_GROUPS.LEHRGANG_BOOKINGS],
      },
      {
        title: 'TeilnehmerInnensuche',
        path: '/user/search',
        requiredGroups: [config.ALLOWED_GROUPS.LEHRGANG_BOOKINGS],
      },
    ],
  },
  {
    id: 'organizations',
    bundleUrl: config.ORGANIZATIONS_COCKPIT_URL,
    navigationTitle: 'Organisationen',
    navigationIcon: 'Business',
    requiredGroups: [config.ALLOWED_GROUPS.ORGANIZATIONS_COCKPIT],
    subMenu: [
      {
        title: 'Veranstaltungskonfiguration',
        path: '/events',
        routerPath: '/events*',
        requiredGroups: [config.ALLOWED_GROUPS.ORGANIZATIONS_COCKPIT],
      },
    ],
  },
];
