export const i18n_de = {
  common: {
    logout: 'Abmelden',
  },
  pages: {
    noPermissionRoute: 'Kein Berechtigung!',
    noAuthorization: {
      pageTitle: 'Keine Berechtigungen vergeben',
      pageContent: `
        Aktuell hast du keine Berechtigungen, um auf das Cockpit zuzugreifen.<1/>
        Die Berechtigungen werden über deine Gruppenzugehörigkeiten gesteuert. Einen Überblick über die Berechtigten Gruppen und ihre Zugriffe findest du <2>in unserem wiki</2><1/>
        Wende dich an einen der Gruppeneigentümer oder erstelle ein Service Now Ticket, um zur Gruppe hinzugefügt zu werden.
        <1/><1/>
        Solltest du keine passende Gruppe in der Liste finden, melde dich bitte per Mail bei unserem <3>Team-Verteiler</3>, um zu klären für was du als Einzelperson Zugriffe brauchst oder ob dein Team Zugriffe braucht.
        <1/><1/>
        Bitte melde dich melde dich ab und versuche dich später nochmal per Haufe-SSO anzumelden, wenn die besprochenen Berechtigungen vergeben wurden. 
        <1/><1/>`,
    },
  },
  alert: {
    networkCheck: {
      noAuthorization:
        'Cockpit API nicht erreichbar - du musst im Haufe Netzwerk bzw. mit dem VPN verbunden sein, um das Cockpit nutzen zu können!',
    },
  },
};
