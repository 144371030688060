import React, { FunctionComponent } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import LightHouseTheme from './LightHouseTheme';

const LightHouseThemeProvider: FunctionComponent = ({ children }) => {
  return (
    <StylesProvider>
      <ThemeProvider theme={LightHouseTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default LightHouseThemeProvider;
