import { FunctionComponent, useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from '../layout/Dashboard';
import RestrictedRoute from './RestrictedRoute';
import config from '../config';
import AuthenticatedContext from '../AuthenticatedContext';
import { microfrontends } from '../microfrontends';
import { MicroFrontendContainer } from '../components/MicroFrontendContainer/MicroFrontendContainer';

const microFrontendRoutes = microfrontends.map((microFrontend) => ({
  groups: microFrontend.requiredGroups,
  route: `/${microFrontend.id}`
}));

const Router: FunctionComponent = () => {

  const authenticatedContext = useContext(AuthenticatedContext);
  const { search } = useLocation();

  const defaultRoute = microFrontendRoutes.find((microFrontendRoute) => 
  microFrontendRoute.groups.filter(value => authenticatedContext.groups.includes(config.ALLOWED_GROUPS.SUPER_USER) || authenticatedContext.groups?.includes(value)).length > 0
  )?.route || microFrontendRoutes[0].route

  return (
    <BrowserRouter>
    <Dashboard>
      <Switch>
        {
          microfrontends.map(microfrontend => {
            if (microfrontend.subMenu.length > 0) {
              return microfrontend.subMenu.map(subMenuItem => (
                <RestrictedRoute
                  requiredGroups={microfrontend.requiredGroups}
                  path={`/${microfrontend.id}${subMenuItem.routerPath ?? subMenuItem.path}`}
                  children={<MicroFrontendContainer microfrontend={microfrontend} />}
                  key={`${microfrontend.id}-${subMenuItem.path}`}
                />
              ))
            } else {
              return <RestrictedRoute
                requiredGroups={microfrontend.requiredGroups}
                path={`/${microfrontend.id}*`}
                children={<MicroFrontendContainer microfrontend={microfrontend} />}
                key={microfrontend.id}
              />
            }
          })
        }
        <Route exact path="/lu-preview" render={() => (<Redirect to={`/lupreview${search}`} />)} />
        <Route exact path="/certificates" render={() => (<Redirect to={`/certificates/search`} />)} />
        <Route exact path="/" render={() => (<Redirect to={defaultRoute} />)} />
      </Switch>
      </Dashboard>
    </BrowserRouter>

  );
};

export default Router;
