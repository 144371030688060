import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from '../src/router/Router';
import LightHouseThemeProvider from '../src/theme/LightHouseThemeProvider';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Auth } from 'aws-amplify';
import { getCurrentSession, init as initAuth } from './services/authService';
import NoAuthorizationPage from './pages/NoAuthorization/NoAuthorizationPage';

import Cookies from 'universal-cookie';
import AuthenticatedContext from './AuthenticatedContext';
import { hasCockpitAccess } from './utils/userPermissions';
import config from './config'
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import NetworkCheck from './components/NetworkCheck/NetworkCheck';

initAuth()

const App: FunctionComponent = () => {

  const [currentSession, setCurrentSession] = useState<CognitoUserSession | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userInfo, setUserInfo] = useState<Record<string, any> | null>(null);
  const [groups, setGroups] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cookies = new Cookies();

    const getUser = async () => {
      const session = await getCurrentSession();
      if (!session) {
        cookies.set('callbackUrl', window.location.href, { path: '/' });
        Auth.federatedSignIn();
      } else {
        if(cookies.get('callbackUrl')){
          window.history.pushState({}, '', cookies.get('callbackUrl'));
          cookies.remove('callbackUrl')
        }
        setCurrentSession(session);
        const idTokenData = session.getIdToken().payload;
        setUserInfo(idTokenData);
      
        const preformattedGroups = idTokenData?.['custom:groups']?.replace('[','')?.replace(']','')?.split(',') ?? []
        const ADGroups = preformattedGroups.map((group: string) => config.USER_GROUPS[group.trim()]).flat()
        const cognitoGroups: string[] = idTokenData?.['cognito:groups'] || [];
        setGroups(cognitoGroups.concat(ADGroups))

        setLoading(false)
      }
    }
    getUser();
  }, []);


  return (
    loading
      ? <LinearProgress />
      :
        <LightHouseThemeProvider>
          {
            currentSession && hasCockpitAccess(groups)
            ? <AuthenticatedContext.Provider value={{
              groups,
              firstName: userInfo?.given_name,
              lastName: userInfo?.family_name,
            }}>
              <NetworkCheck />
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </AuthenticatedContext.Provider>
            : <NoAuthorizationPage/>
          }
        </LightHouseThemeProvider>
  );
}

export default App;
