import config from '../config';

export const hasCockpitAccess = (userGroups: string[]): boolean => {
  const allowedGroups = Object.values(config.ALLOWED_GROUPS);
  return userGroups.filter((userGroup) => allowedGroups.includes(userGroup)).length > 0;
};

export const hasRequiredGroup = (requiredGroups: string[], userGroups: string[]): boolean => {
  // Inject super user group to always return true for super users
  return (
    userGroups.filter((userGroup) => [...requiredGroups, config.ALLOWED_GROUPS.SUPER_USER].includes(userGroup)).length >
    0
  );
};
