import { Avatar, Button, createStyles, makeStyles, Paper, Popover, Theme } from "@material-ui/core";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthenticatedContext from "../AuthenticatedContext";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import {Auth} from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userMenu: {
      padding: theme.spacing(2)
    },
    logoutButton: {
      marginTop: theme.spacing(2)
    }
  })
);

const UserMenu: FunctionComponent = (...props) => {

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const userMenuOpen = Boolean(anchorEl);
  const filterId = userMenuOpen ? 'simple-popover' : undefined;
  const classes = useStyles();

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  return <AuthenticatedContext.Consumer>
    {({ firstName, lastName }) => {
      return (<>
      <Avatar aria-describedby={filterId} onClick={handleAvatarClick}>{ `${firstName?.[0]}${lastName?.[0]}` }</Avatar>
      <Popover
        id={filterId}
        anchorEl={anchorEl}
        onClose={handleUserMenuClose}
        open={userMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper className={classes.userMenu}>
          <div>
            {`${firstName} ${lastName}`}
          </div>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<LogoutIcon />}
            className={classes.logoutButton}
            onClick={() => Auth.signOut()}
          >
            { t('common.logout') }
          </Button>
        </Paper>
      </Popover>
    </>)}}
  </AuthenticatedContext.Consumer>
}

export default UserMenu;
