import { Auth, Amplify } from 'aws-amplify';
import config from '../config';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

let currentSession: CognitoUserSession | null = null;

export const init = (): void => {
  Amplify.configure({
    Auth: {
      region: 'eu-central-1',
      identityPoolId: config.COGNITO_IDENTITY_POOL_ID,
      userPoolId: config.COGNITO_USER_POOL_ID,
      userPoolWebClientId: config.COGNITO_USER_POOL_WEBCLIENT_ID,
    },
  });

  const oauth = {
    domain: config.COGNITO_DOMAIN,
    scopes: ['profile', 'email', 'openid'],
    redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT,
    responseType: 'code',
  };

  Auth.configure({ oauth });
};

export const getCurrentSession = async (): Promise<CognitoUserSession | null> => {
  try {
    currentSession = await Auth.currentSession();
  } catch (error) {
    currentSession = null;
  }

  return currentSession;
};

export const getJWT = async (): Promise<string | null> => {
  try {
    return (await getCurrentSession())?.getIdToken()?.getJwtToken() || null;
  } catch (error) {
    return null;
  }
};
