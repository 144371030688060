import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import AuthenticatedContext from "../AuthenticatedContext";
import { hasRequiredGroup } from "../utils/userPermissions";

interface IRestrictedRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>
  path: string
  requiredGroups: string[]
}

const RestrictedRoute: FunctionComponent<IRestrictedRouteProps> = ({
  component,
  path,
  requiredGroups,
  children
}) => {

  const { t } = useTranslation();

  return <AuthenticatedContext.Consumer>
    {({ groups }) => {
      return hasRequiredGroup(requiredGroups, groups) 
        ? <Route 
            exact path={path} 
            component={component}
          >
            {children}
          </Route>
        : <>{t('pages.noPermissionRoute')}</>
    }}
  </AuthenticatedContext.Consumer>
}

export default RestrictedRoute;