import { ListItem, ListItemText, Theme, createStyles, makeStyles } from "@material-ui/core";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import ColorPalette from "../../theme/ColorPalette";

interface ISubMenuItem {
  title: string;
  path: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeLink: {
      backgroundColor: ColorPalette.primary.main,
      '&:hover': {
        backgroundColor: ColorPalette.primary.main + '!important',
      }
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export const SubMenuItem: FunctionComponent<ISubMenuItem> = ({path, title}) => {

  const classes = useStyles();

  return <ListItem button component={NavLink} to={path} className={classes.nested} activeClassName={classes.activeLink}>
    <ListItemText primary={title} />
  </ListItem>
}