import React, {FunctionComponent, ReactElement} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ColorPalette from "../theme/ColorPalette";
import logo from "../assets/images/logo/logo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(8),
      height: '100vh',
      overflow: 'auto',
      backgroundColor: ColorPalette.monochrome.grey5,
    },
    logo: {
      height: 70,
      marginBottom: theme.spacing(1) * -1,
      marginTop: theme.spacing(1) * -1,
    }
  }),
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

interface ILayoutProps {
  children: ReactElement | ReactElement[];
}

const Dashboard: FunctionComponent<ILayoutProps> = ({ children }, _props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={1}>
        <Toolbar>
            <img
              alt="Haufe Akademie Logo"
              className={classes.logo}
              src={logo}
            />
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        {/*<div className={classes.toolbar} />*/}
          {children}
      </main>
    </div>
  );
}

export default Dashboard;