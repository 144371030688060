import { createContext } from "react";

export interface IAuthenticatedContextProps {
  groups: string[],
  firstName?: string,
  lastName?: string,
}

const initialProps: IAuthenticatedContextProps = {
  groups: [],
  firstName: undefined,
  lastName: undefined,
}

const AuthenticatedContext = createContext<IAuthenticatedContextProps>(initialProps);

export default AuthenticatedContext;
