import React, { FunctionComponent } from 'react';

import Typography from "@material-ui/core/Typography";
import DefaultDashboard from '../../layout/DefaultDashboard'

import { Box, Button, Container } from '@material-ui/core';
import { Auth } from '@aws-amplify/auth';
import { useTranslation, Trans } from 'react-i18next';


const NoAuthorizationPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <DefaultDashboard>
      <Box pb={5} pt={5} style={{ backgroundColor: 'transparent' }}>
        <Container maxWidth={false}>
          <Typography variant="h1">
            {t('pages.noAuthorization.pageTitle')}
          </Typography>
          <Trans i18nKey={'pages.noAuthorization.pageContent'} components={{
            1: <br/>, 
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            2: <a href="https://mywiki.grp.haufemg.com/display/TEAMADL/LUC%3A+Berechtigungsmanagement" target="blank" rel="noreferrer"/>, 
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            3: <a href='mailto:_AKA_Domaene_LU@haufe-lexware.com'/>
          }}/>
          <Button onClick={() => Auth.signOut()} variant="contained" color='primary'>
          {t('common.logout')}
          </Button>
        </Container>
      </Box>
    </DefaultDashboard>
  );
};

export default NoAuthorizationPage;
