import React, {
  FunctionComponent,
  useContext,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';

import { useHistory } from 'react-router';
import { IMicroFrontend} from '../../microfrontends';
import AuthenticatedContext from '../../AuthenticatedContext';
import { IMicroFrontendInstance } from '../../types';
import { hasCockpitAccess, hasRequiredGroup } from '../../utils/userPermissions';
import { getJWT } from '../../services/authService';

interface IMicrofrontendProps {
  microfrontend: IMicroFrontend
}

const MicroFrontendContainer: FunctionComponent<IMicrofrontendProps> = ({ microfrontend }) => {
  const history = useHistory();
  const authenticatedContext = useContext(AuthenticatedContext);

  useEffect(() => {
    const scriptId = `script-${microfrontend.id}`;
    if (document && document.getElementById(scriptId)) {
      renderMicrofrontendScreen();
      return cleanup;
    }
    const script = document.createElement('script');
    script.id = scriptId;
    script.crossOrigin = '';
    script.src = microfrontend.bundleUrl;
    script.onload = renderMicrofrontendScreen;
    document.head.appendChild(script);

    return cleanup;
  });

  const cleanup = () => {
    const microfrontendDiv = document.getElementById(microfrontend.id);
    if (microfrontendDiv) {
      ReactDOM.unmountComponentAtNode(microfrontendDiv);
    }
  };

  const renderMicrofrontendScreen = () => {
    const microfrontendMeta = {
      relativeUrl: `/${microfrontend.id}`,
      authenticatedContext,
      hasCockpitAccess: hasCockpitAccess,
      hasRequiredGroup: hasRequiredGroup,
      getJWT: getJWT
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const microFrontend: IMicroFrontendInstance = (window as any)[microfrontend.id]
    microFrontend(
      microfrontend.id, 
      history, 
      microfrontendMeta
    ).render()
  };

  return (
    <>
      <main id={microfrontend.id} />
    </>
  )
}

export { MicroFrontendContainer };