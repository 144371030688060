import React, { FunctionComponent, ReactElement, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import {Link as RouterLink} from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ColorPalette from "../theme/ColorPalette";
import logo from "../assets/images/logo/logo.svg";
import {useTranslation} from "react-i18next";
import { Auth } from 'aws-amplify';
import AuthenticatedContext from '../AuthenticatedContext';
import { hasRequiredGroup } from '../utils/userPermissions';
import UserMenu from './UserMenu';
import { microfrontends } from '../microfrontends';
import getIcons from '../utils/icons';
import { MenuItem } from './MainMenu/MenuItem';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      '& .MuiListItem-button:hover': {
        backgroundColor: ColorPalette.monochrome.grey70,
      },
      '& .MuiListItemText-root': {
        marginTop: 0,
        marginBottom: 0,
        color: ColorPalette.monochrome.white
      },
      '& .MuiSvgIcon-root': {
        color: ColorPalette.monochrome.white
      },
      '& .MuiListItemIcon-root': {
        minWidth: 36
      },
      '& .MuiListItemText-primary': {
        color: ColorPalette.monochrome.white
      },
      '& .MuiListItem-gutters': {
        paddingLeft: 18,
        paddingRight: 18,
        paddingTop: 16,
        paddingBottom: 16
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: ColorPalette.monochrome.white,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: ColorPalette.monochrome.grey60,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: ColorPalette.monochrome.grey80,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(8),
      height: '100vh',
      overflow: 'auto',
      backgroundColor: ColorPalette.monochrome.grey5,
    },
    logo: {
      height: 25
    },
    activeLink: {
      backgroundColor: ColorPalette.primary.main,
      '&:hover': {
        backgroundColor: ColorPalette.primary.main + '!important',
      }
    },
    logoutBtn: {
      marginTop: theme.spacing(3)
    },
    avatar: {
      marginLeft: 'auto'
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    subMenuLevel1: {
      marginLeft: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      borderLeft: '1px solid',
      borderColor: theme.palette.common.white,
      fontSize: '12px',
      '& .MuiListItem-gutters': {
        padding: '10px 16px'
      },
      '& .MuiListItemIcon-root': {
        minWidth: '30px',
        '& .MuiSvgIcon-root': {
          fontSize: '18px'
        }
      },
      '& .MuiTypography-root': {
        fontSize: '12px'
      }
    }
  }),
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

interface ILayoutProps {
  children: ReactElement | ReactElement[];
}

const Dashboard: FunctionComponent<ILayoutProps> = ({ children }, props: Props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <AuthenticatedContext.Consumer>
      {({ groups }) => {
        return (
          <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
              <>
                {
                  microfrontends.map(microfrontend => {
                    const Icon =  getIcons(microfrontend.navigationIcon)

                    if (!hasRequiredGroup(microfrontend.requiredGroups, groups)) {
                      return <></>;
                    }

                    const subMenuItems = microfrontend.subMenu.map(item => ({
                      ...item,
                      path: `/${microfrontend.id}${item.path}`
                    }))

                    if (microfrontend.subMenu.length > 0) {
                      return <MenuItem
                        icon={Icon}
                        title={microfrontend.navigationTitle}
                        subMenuItems={subMenuItems}
                      />
                    } else {
                      return <MenuItem
                        icon={Icon}
                        title={microfrontend.navigationTitle}
                        path={`/${microfrontend.id}`}
                    />
                    }
                  })
                }
              </>
              <Divider style={{marginTop: 10}} />          
              <ListItem button onClick={() => Auth.signOut()} className={classes.logoutBtn}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText primary={t('common.logout')} />
              </ListItem>
            </List>
          </div>
        )}}
    </AuthenticatedContext.Consumer>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <AuthenticatedContext.Consumer>
      {({ firstName: _firstName, lastName: _lastName }) => {
        return <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar} elevation={1}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <RouterLink to="/">
                <img
                  alt="Haufe Akademie Logo"
                  className={classes.logo}
                  src={logo}
                />
              </RouterLink>
              <div className={classes.avatar}>
                <UserMenu />
              </div>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
              {children}
          </main>
        </div>
    }}
    </AuthenticatedContext.Consumer>
  );
}

export default Dashboard;