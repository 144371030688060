import { SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import * as icons from '@material-ui/icons'

//
// eslint-disable-next-line @typescript-eslint/ban-types
function getIcons(iconId: string): OverridableComponent<SvgIconTypeMap<{}, "svg">> {
  const iconNames = Object.keys(icons)
  return iconNames.includes(iconId) 
    ?  (icons as Record<string, icons.SvgIconComponent>)[iconId]
    : icons.Clear
}
export default getIcons
